.premium,
.smart,
.default {
  border-radius: 16px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

.premium {
  background-color: rgba(226, 57, 254, 0.12);
  color: #E239FE;
}

.smart {
  background-color: rgba(85, 221, 131, 0.12);
  color: #55DD83;
}

.default {
  background-color: rgba(57, 86, 254, 0.12);
  color: #3956FE;
}

.transaction-currency svg {
  height: 16px;
  width: 16px;
}

.button-cell button {
  padding: 10px 13px;
  min-width: unset;
  border-radius: 12px;
}