@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-size: 16px;
    line-height: 26px;
    background-color: #F3F2F9;
}

html, body, #root {
    @apply text-default;
}

html, body, #root. div {
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

html::-webkit-scrollbar,
body::-webkit-scrollbar,
#root::-webkit-scrollbar,
div::-webkit-scrollbar {
    display: none;
}

body {
    overflow-x: hidden;
}

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

header.filled {
    background: linear-gradient(#FBFBFD 50% ,#E0F3FF);
    box-shadow: 8px 8px 24px 0 rgba(175, 195, 229, 0.31);
}

a.disabled {
    pointer-events: none;
    cursor: default;
}

div[data-testid="flowbite-tooltip-target"] {
    width: 100%;
}

.mixed-currency svg:not(:first-child) {
    margin-left: -4px;
}

@keyframes blinkAnimation {
    0% {
      opacity: 1;
    }
    30% {
      opacity: 0.7;
    }
    70% {
      opacity: 0.3;
    }
    100% {
      opacity: 0;
    }
  }
  
  .blinking-dot {
    animation: blinkAnimation 1s linear infinite;
    animation-direction: alternate;
    font-size: 30px;
    padding: 0;
    margin: 0;
  }

  .online-span{
    line-height: 20px;
    font-weight: 600;
    padding: 2px 15px;
    flex-direction: row;
    display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(115, 212, 22, 0.08);
  border-radius: 12px;
  /* padding: 2px 16px; */
  /* font-size: 16px; */
  color: #73D516;
  cursor: pointer;
  }

  .online-span  span{
    /* margin-right: 10px; */
  }